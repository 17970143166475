import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const CardContainer = styled.div`
  p {
    font-family: "Sora", Helvetica, sans-serif !important;
  }

  .header {
    padding: 16px;
    background: ${grayscale[100]};
    border-bottom: 1px solid #FFDAB7;
    
    @media ${device.tablet} {
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-bottom: 2px solid ${grayscale[200]};
      margin-bottom: 8px;
    }
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid ${grayscale[100]};

    @media ${device.tablet} {
      border-bottom: none;

      .info {
        width: 176px;
      }

      .info:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .info:not(:first-child):not(:last-child) {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 0px 32px;
        /* &::before {
          content: '';
          position: absolute;
          width: 1px;
          left: -40px;
          top: 10px;
          display: block;
          height: 32px;
          border-left: 1px dashed ${grayscale[200]};
        }
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          right: -40px;
          top: 10px;

          display: block;
          height: 32px;
          border-right: 1px dashed ${grayscale[200]};
        } */
      }

      .info:first-child {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          right: -24px;
          top: 10px;
          display: block;
          height: 32px;
          border-right: 1px dashed ${grayscale[200]};
        }
      }

      .info:last-child {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 1px;
          left: -24px;
          top: 10px;
          display: block;
          height: 32px;
          border-left: 1px dashed ${grayscale[200]};
        }
      }
    }

    @media ${device.desktopLG} {
      .info {
        width: 280px;
      }
    }

    @media ${device.desktopXL} {
      .info {
        width: 340px;
      }
    }
  }
`
