import { device } from 'src/styles/breakpoints'
import { grayscale, white } from 'src/styles/colors'
import styled, { css } from 'styled-components'

export const CustomHighlight = css`
  align-items: center;
  min-height: calc(100vh - 64px);

  @media ${device.desktopLG} {
    min-height: 100vh;
  }

  &:before {
    background-color: #6A6E81;
    mix-blend-mode: multiply;
    background-image: none;
  }
  
  > .container {
    padding-top: 0; 
  }  

  .fixed-bread {
    
    @media ${device.tablet} {
      padding-left: 20px;
      padding-right: 20px;
      position: relative;
      top: auto;
    }
  }
`

export const Wrapper = styled.div`

  h2 {
    margin-bottom: 20px;
  }

  h1, h2, h3, h4, h5 {
    font-family: "Sora", Helvetica, sans-serif;
  }

  main {
    p {
      line-height: 1.4;
    }
    span {
      line-height: 1.4;
    }
  }
`

export const RLPBenefitsSection = styled.section`
  .btn {
    @media ${device.desktopLG} {
      max-width: 100%;
    }
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper { 
    max-width: 450px;
    margin: 0 auto;
  }
`

export const BtnSize = styled.div`
 .btn {
    @media ${device.desktopLG} {
      max-width: 100%;
    }
  }
`

export const HowItWorksSection = styled.section`
  .border-table-header {
    border-radius: 8px 8px 0 0;
    h3 {
      text-transform: uppercase;
    }
  }
  
  .table {
    div:nth-child(even) {
      background-color: ${grayscale[100]};        
    }
    div:nth-child(odd) {
      background-color: ${white};
    }
    div:last-child {
      border-radius: 0 0 8px 8px;
    }
  }
  .shadow {
    border-radius: 8px;
  }
`

export const BoxedShadow = styled.div`
  box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  max-width: none;

  @media ${device.desktopLG} {
    min-height: 110px;
  }
`

export const FAQSection = styled.section`
  .search-input {
    background: ${white};
  }
`

export const NumbersRLPInterInvest = styled.section`
  h3 {
    color: #495057;
  }
`
export const CardContainer = styled.div`
  p {
    margin-bottom: 0px !important;
  }

  .header {
    padding: 16px;
    background: ${grayscale[100]};
    border-bottom: 1px solid #FFDAB7;
    
    @media ${device.tablet} {
      border-radius: 16px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border: 2px solid ${grayscale[200]};
      margin-bottom: 8px;
    }
  }

  .body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid ${grayscale[100]};

    @media ${device.tablet} {
      border-bottom: none;
      justify-content: center;

      .info {
        width: 176px;
      }

      .info:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .info:not(:first-child):not(:last-child) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0px 32px;
        border-left: 1px dashed ${grayscale[200]};
        border-right: 1px dashed ${grayscale[200]};
      }
    }

    @media ${device.desktopLG} {
      .info {
        width: 280px;
      }
    }

    @media ${device.desktopXL} {
      .info {
        width: 340px;
      }
    }
  }
`
